import React from "react"

import Layout from "../components/layout/layout"
import { Publications } from "../components/publications/publications"

const IndexPage = () => (
  <Layout title="Publications">
    <Publications />
  </Layout>
)

export default IndexPage
