import React from "react"
import { StaticQuery, graphql } from "gatsby"

import styles from "./publications.module.scss"

const formatDate = (dateString) => {
  const date = new Date(dateString)

  return `${date.toLocaleDateString("default", {
    month: "long",
  })} ${date.getDay()}, ${date.getFullYear()}`
}

export const Publications = () => (
  <StaticQuery
    query={graphql`
      {
        graphCMSData {
          publications(orderBy: date_DESC) {
            id
            title
            description
            date
            cover {
              url
            }
            url
            publisher {
              name
              url
            }
          }
        }
      }
    `}
    render={(data) => (
      <main>
        {data.graphCMSData.publications.map((publication) => (
          <article key={publication.id}>
            <div>
              <img
                src={publication.cover.url}
                className={styles.thumbnail}
                alt={publication.title}
              />
            </div>
            <header>
              <h1 className={styles.title}>{publication.title}</h1>
              <div className={styles.meta}>
                <ul className={styles.metaDetail}>
                  <li className={styles.metaDetailDate}>
                    {formatDate(publication.date)}
                  </li>
                  <li className={styles.metaDetailPublisher}>
                    <a
                      href={publication.publisher.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {publication.publisher.name}
                    </a>
                  </li>
                </ul>
              </div>
            </header>
            <div>
              <p className={styles.description}>{publication.description}</p>
            </div>
            <footer className={styles.footer}>
              <a
                href={publication.url}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.readMore}
              >
                Read more
              </a>
            </footer>
          </article>
        ))}
      </main>
    )}
  />
)
